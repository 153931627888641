export const clientsData = {
  client1: {
    title: "Vaanya Finserv Consultancy Private Limited",
    link: "https://www.zaubacorp.com/company/VAANYA-FINSERVE-CONSULTANCY-PRIVATE-LIMITED/U67190UP2019PTC113677", // External link
  },
  client2: {
    title: "Somvanshi Solar And Power Consultancy Pvt Ltd",
    link: "https://sspcpl.com/",
  }, 
  client3: {
    title: "Grand Dream Resort",
    link: "http://granddreamresort.com/",
  },
  client4: {
    title: "OMK And Sons",
    link: "",
  },
};
